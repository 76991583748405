import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { usePrevious } from '@zola/zola-ui/src/hooks/usePrevious';
import { TagV2, TagV2Size, TagV2Variant } from '@zola/zola-ui/src/components/Tag/TagV2';
import useSlideHoverAnimation from '../useSlideHoverAnimation';

import './navLink.less';

const NavLink = ({
  href,
  children,
  rel,
  target,
  variant,
  className,
  onClick,
  role,
  newTag = false,
  isActive,
  openMenuIndex,
  index,
  activeNavItemIndex,
  setMenuOpenIndex,
  clearMenuTimer,
  setMenuTimerId,
  icon,
}) => {
  const prevOpenMenuIndex = usePrevious(openMenuIndex);
  const animation = useSlideHoverAnimation({
    prevHoverIndex: prevOpenMenuIndex,
    curHoverIndex: openMenuIndex,
    index,
  });
  const handleMouseEnter = () => {
    setTimeout(() => {
      // Delay clearing the timer so react has a chance to update.
      clearMenuTimer();
    }, 0);
    setMenuOpenIndex(true, index);
  };
  const handleMouseLeave = () => {
    const delayAnimation = setTimeout(() => {
      setMenuOpenIndex(false, activeNavItemIndex);
    }, 300);
    JSON.stringify(setMenuTimerId);
    setMenuTimerId(delayAnimation);
  };

  const isAnimation = variant === 'PrimaryNavLink';
  return (
    <a
      className={cx('nav-link', className, {
        [`nav-link--${variant}`]: variant,
        'nav-link--active': isActive,
        [animation]: isAnimation,
        'nav-link--animated': isAnimation,
      })}
      rel={rel}
      target={target}
      href={href}
      role={role}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {icon && <img src={icon} className="nav-link--icon" alt="" aria-hidden />}
      {children}{' '}
      {newTag && (
        <TagV2 variant={TagV2Variant.SKY} size={TagV2Size.SMALL}>
          New
        </TagV2>
      )}
    </a>
  );
};

NavLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  target: PropTypes.string,
  rel: PropTypes.string,
  role: PropTypes.string,
  variant: PropTypes.oneOf([
    'PrimaryNavLink',
    'PrimaryNavSubLink',
    'SecondaryNavLink',
    'SecondaryNavSubHeader',
    'SecondaryNavSubLink',
    'PromoNavLink',
    'PromoNavSubLink',
    'SecondaryNavOtherResourcesLink',
  ]),
  onClick: PropTypes.func,
  newTag: PropTypes.bool,
  openMenuIndex: PropTypes.number,
  index: PropTypes.number,
  activeNavItemIndex: PropTypes.number,
  setMenuOpenIndex: PropTypes.func,
  clearMenuTimer: PropTypes.func,
  setMenuTimerId: PropTypes.func,
  icon: PropTypes.string,
};

NavLink.defaultProps = {
  target: '_self',
  openMenuIndex: null,
  index: null,
  activeNavItemIndex: null,
  setMenuOpenIndex: () => {},
  clearMenuTimer: () => {},
  setMenuTimerId: () => {},
  icon: null,
};

export default NavLink;
